import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout/index"
import SEO from "../components/seo"
import StyledSectionBox from "../components/sectionBox/index"
import ContactForm from "../components/contactForm/index"
import GoogleMaps from "../components/googleMaps/index"
import { StyledContainer } from "../components/containers/index"

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 24px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr;
    .form {
      order: 1;
    }

    .address {
      order: 2;
    }
  }
`

const ContactPageEn = ({ location, data: { background, map } }) => {
  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="en-US"
      />
      <StyledSectionBox
        background={background.childImageSharp.fluid}
        title="contact"
      />
      <StyledContainer>
        <StyledWrapper>
          <div className="address">
            <h3 style={{ marginBottom: 16 }}>
              Frameway Group
              <br />
              譽威集團
            </h3>
            <ul>
              <li>
                Unit 4-8, 3/F., Block A, Vigor Industrial Building, 14-20 Cheung
                Tat Road, Tsing Yi, N.T., H.K.
              </li>
              <li>青衣長達路14-20號偉力工業大廈A座3樓4-8室</li>
              <li>Tel: +852 2497 6113</li>
              <li>Fax: +852 2433 0168 / +852 2432 4511</li>
              <li>
                Enquiry email: fwgroup@frameway.com / marketing@frameway.com
              </li>
            </ul>
            <h3 style={{ marginBottom: 16 }}>Location</h3>
            <p>
              <strong>Corporate Headquarters</strong>
            </p>
            <p>
              <strong>
                Frameway Group <br />
                譽威集團
              </strong>
            </p>
            <ul>
              <li>
                Unit 4-8, 3/F., Block A, Vigor Industrial Building, 14-20 Cheung
                Tat Road, Tsing Yi, N.T., H.K.
              </li>
              <li>青衣長達路14-20號偉力工業大廈A座3樓4-8室</li>
            </ul>
          </div>
          <div className="form">
            <h3 style={{ marginBottom: 16 }}>
              <FormattedMessage id="contactUs" />
            </h3>
            <ContactForm location={location} />
          </div>
        </StyledWrapper>
        <GoogleMaps address="14 Cheung Tat Rd, Tsing Yi, Hong Kong" />
        <div>
          <h3 style={{ marginBottom: 16 }}>China Plants</h3>
          <p>
            <strong>
              Frameway Seiko Technology (Shenzhen) Company Limited <br />
              譽威精工科技(深圳)有限公司
            </strong>
          </p>
          <ul>
            <li>深圳市寶安區松崗街道沙浦社區洋涌工業區2路3號</li>
            <li>
              No. 3, Road 2 Yang Yong Industry District, Sha Pu Village, Song
              Gang Town, Bao An, Shen Zhen City, Guang Dong Province, P.R. China
            </li>
          </ul>
          <p>
            <strong>
              Frameway Metal Manufacturing (Shenzhen) Limited <br />
              譽威五金製品(深圳)有限公司
            </strong>
          </p>
          <ul>
            <li>深圳市寶安區松崗街道沙浦社區第三工業區</li>
            <li>
              The Third Industrial Zone, Sha Pu Village, Song Gang Town, Bao An,
              Shen Zhen City, Guang Dong Province, P.R. China
            </li>
          </ul>
          <p>
            <strong>
              Hong Wai (Shenzhen) Metal Manufacturing Limited <br />
              深圳康威五金制品有限公司
            </strong>
          </p>
          <ul>
            <li>深圳市寶安區松崗街道沙浦社區沙浦二路105號</li>
            <li>
              105 Sha Pu 2nd Road, Song Gang Town, Bao An, Shen Zhen City ,Guang
              Dong Province, P.R. China
            </li>
          </ul>
          <p>
            <strong>
              Frameway Kyoritu Limited / Shenzhen Kyo Ri Tu Precision Metal
              Company Limited <br />
              協立譽威有限公司 / 深圳協理通精密五金有限公司
            </strong>
          </p>
          <ul>
            <li>深圳市寶安區松崗街道沙浦二村(沙浦第三工業區)</li>
            <li>
              Sha Pu Village II, Song Gang Town, Bao An, Shen Zhen City ,Guang
              Dong Province, P.R. China
            </li>
          </ul>
        </div>
        <Img
          fluid={map.childImageSharp.fluid}
          style={{ marginBottom: 64, marginTop: 40 }}
        />
      </StyledContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    background: file(relativePath: { eq: "contact/contact-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    map: file(relativePath: { eq: "contact/google-map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1224) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ContactPageEn
